/** @import: Vendor. */
@import "../vendor/normalize/normalize.css";
@import "../vendor/owl/owl.carousel.min.css";

/** @import: Main. */
@import "reset";
@import "vars";
@import "fonts";

/** Body. */
body {
	font-family: font(main);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  padding-top: 100px;

  /** Mobile. */
  @media (max-width: 1023px) {
    padding-top: 78px;
  }

  &::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: .6;
      pointer-events: initial;
    }
  }
}

/** Layout. */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1300px) {
  	padding: 0 25px;
  }
}

/** @import: Globals. */
@import "globals/header";
@import "globals/footer";
@import "globals/menu";

/** @import: Elements. */
@import "elements/general";
@import "elements/buttons";
@import "elements/accordions";
@import "elements/progress-screen";

/** @import: Modals. */
// @import "modals/{{modal_name}}";

/** @import: Sections. */
@import "sections/cta";
@import "sections/blocks";
@import "sections/page-hero";

/** @import: Pages. */
@import "pages/home";
@import "pages/about-us";
@import "pages/calculator";
@import "pages/accessibility-statement";
@import "pages/regulatory-disclosures";
@import "pages/appraisal-policy";
@import "pages/contact-us";
@import "pages/lp-cafe";
@import "pages/investment-services";
@import "pages/residential-mortgages";
@import "pages/resolve-your-complaints";
@import "pages/resource-centre";
@import "pages/terms-of-use";
@import "pages/deposit-insurance-information";
@import "pages/declaration-and-agreement";
@import "pages/legal";
@import "pages/privacy";
@import "pages/vision-values";
@import "pages/applications-centre";
@import "pages/arms-length-mortgage-forms";
@import "pages/404";
@import "pages/saving-deposits";
@import "pages/mortgage-administration";
@import "pages/what-is-changing";
@import "pages/browser-requirements";
@import "pages/privacy-code";
@import "pages/registered-account-forms";
@import "pages/search";
@import "pages/coming-soon";
@import "pages/borrowing-residential-mortgage";
@import "pages/our-community";

/** @import: Pages [Mobile]. */
// @import "pages/mobile/{{page_name}}";
