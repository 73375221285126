.section-blocks {
	background-color: color(grey);
	padding: 100px 0;

	/** Mobile. */
	@media (max-width: 1023px) {
		padding: 100px 0 70px;
	}

	.flex-wrapper {
		position: relative;
		display: flex;
		justify-content: space-between;

		/** Mobile. */
		@media (max-width: 1023px) {
			flex-wrap: wrap;
		}

		.item {
			position: relative;
			border: 1px solid white;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 175px;
			width: 270px;
			transition: background-color time(default) ease(inout), border-color time(default) ease(inout);

			/** Mobile. */
			@media (max-width: 1200px) {
				width: 230px;
			}
			@media (max-width: 1023px) {
				width: calc(50% - 15px);
				min-height: 140px;
				margin-bottom: 30px;
			}

			&-link {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 3;
			}

			&-icon {
				position: relative;
				height: 85px;
				text-align: center;

				/** Mobile. */
				@media (max-width: 1023px) {
					height: 55px;
					margin-bottom: 10px;
				}

				img {
					max-width: 130px;
					max-height: 100%;
				}

				svg path {
					fill: white;
				}
			}

			&-title {
				color: white;
				font-size: 24px;
				font-family: font(title);
				font-weight: normal;
				text-align: center;
				line-height: 1.2;
				transition: color time(default) ease(inout);
				padding: 0 15px;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 18px;
				}
				@media (max-width: 355px) {
					font-size: 16px;
				}
			}

			&:hover {
				background-color: #231F20;
				border-color: #231F20;
			}
		}
	}
}