body.page-template-template-lp-cafe {
  padding: 0;
}

#page-lp-cafe {
  .section-header {
    background-color: white;
    position: relative;
    width: 100%;

    .flex-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 120px;

      /** Mobile. */
      @media (max-width: 1023px) {
        height: 80px;
      }

      .logo {
        img {

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 80px;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;

        &-phone {
          color: color(grey);
          font-size: 14px;
          font-weight: normal;

          a {
            color: color(grey);
            font-size: 14px;
            font-weight: bold;
          }
        }

        &-website {
          position: relative;
          top: -1px;
          margin-left: 40px;

          /** Mobile. */
          @media (max-width: 1023px) {
            margin-left: 24px;
          }

          a {
            color: color(grey);
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .section-hero {
    position: relative;
    width: 100%;

    .background {
      position: relative;
      width: 100%;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .section-content {
    padding: 40px 0 80px;

    .wrapper {
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 80px;
        }

        &--title {
          color: color(grey);
          font-family: font(title);
          font-size: 40px;
          line-height: 56px;
          font-weight: 900;
          width: 660px;
          margin-bottom: 25px;

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 100%;
            font-size: 24px;
            line-height: 34px;
          }
        }

        &--description {
          width: 920px;

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 100%;
          }

          p {
            color: color(grey);
            font-size: 24px;
            font-weight: normal;
            line-height: 29px;

            /** Mobile. */
            @media (max-width: 1023px) {
              font-size: 16px;
              line-height: 29px;
            }
          }
        }

        &--cta {
          margin-top: 25px;
        }
      }
    }
  }

  .section-features {
    background-color: #F1F1F1;
    padding: 80px 0;

    .headline {
      text-align: center;

      &-title {
        color: color(grey);
        font-family: font(title);
        font-size: 32px;
        font-weight: 900;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 24px;
          line-height: 34px;
        }
      }

      &-description {
        color: color(grey);
        font-size: 20px;
        font-weight: normal;
        line-height: 24px;
        margin-top: 20px;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    .container {
      padding: 0 90px;

      /** Mobile. */
      @media (max-width: 1023px) {
        padding: 0 25px;
      }
    }

    .flex-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 25px;
      grid-row-gap: 40px;
      margin-top: 40px;

      /** Mobile. */
      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: relative;
        width: 100%;

        &-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 50px;
          margin-bottom: 10px;
        }

        &-title {
          color: color(grey);
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          height: 50px;
          margin-bottom: 15px;

          /** Mobile. */
          @media (max-width: 1023px) {
            height: auto;
            margin: 0;
          }
        }

        &-description {
          color: color(grey);

          p {
            font-size: 16px;
          }
        }
      }
    }
  }

  .section-contact {
    padding: 80px 0;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 80px 0 0;
    }

    .flex-wrapper {
      display: flex;
      justify-content: center;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .left {
        width: 600px;
        flex-shrink: 0;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
        }

        &-title {
          color: color(grey);
          font-family: font(title);
          font-size: 45px;
          font-weight: 900;

          /** Mobile. */
          @media (max-width: 1023px) {
            font-size: 24px;
            line-height: 35px;
          }
        }

        &-description {
          margin-top: 20px;

          p {
            color: color(grey);
            font-size: 24px;
            font-weight: normal;
            line-height: 29px;

            /** Mobile. */
            @media (max-width: 1023px) {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }

        &-form {
          margin-top: 40px;

          .field-group {
            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .label {
              color: color(grey);
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 10px;
            }

            .field {
              background-color: transparent;
              color: color(grey);
              font-size: 16px;
              font-weight: normal;
              height: 48px;
              border: 1px solid #E2E2E2;
              padding: 0 12px;
              display: block;
              width: 100%;
              outline-color: color(orange);
              border-radius: 8px;
              transition-property: border-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &.invalid .field {
              border-color: red;
            }
          }

          .form-submit {
            margin-top: 30px;

            button {
              border-radius: 8px;
              min-width: 140px;
              height: 48px;
              padding-top: 2px;
              padding-bottom: 0;
            }
          }
        }
      }

      .right {
        background-color: #F1F1F1;
        position: relative;
        width: 385px;
        flex-shrink: 0;
        margin-left: 30px;
        padding: 40px 25px;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100vw;
          left: -25px;
          margin: 30px 0 0;
          text-align: center;
        }

        &-title {
          color: color(grey);
          font-family: font(title);
          font-size: 24px;
          font-weight: 900;
          line-height: 34px;
          margin-bottom: 30px;
        }

        &-items {
          .item {
            &:not(:last-child) {
              margin-bottom: 24px;
            }

            a {
              color: #B45004;
              font-size: 18px;
              font-weight: normal;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &-title {
              color: color(grey);
              font-size: 18px;
              font-weight: normal;
            }

            &:hover {
              a {
                color: color(grey);
              }
            }
          }
        }
      }
    }
  }

  .section-footer {
    background-color: #F1F1F1;
    padding: 100px 0 80px;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 50px 0 80px;
    }

    .container {
      padding: 0 90px;

      /** Mobile. */
      @media (max-width: 1023px) {
        padding: 0 25px;
      }
    }

    .wrapper {
      border-top: 1px solid fade-out(color(grey), .8);

      &-description {
        width: 810px;
        padding-top: 50px;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
          padding-top: 40px;
        }

        p {
          color: color(grey);
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
        }
      }

      &-copyright {
        margin-top: 35px;

        color: color(grey);
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
      }
    }
  }

  .modal-page {
    background-color: fade-out(#4E4E4E, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition-property: opacity, visibility;
    transition-duration: time(default);
    transition-timing-function: ease(inout);

    &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
    }

    &--wrapper {
      background-color: white;
      padding: 40px 25px;
      width: 480px;
      border-radius: 8px;

      /** Mobile. */
      @media (max-width: 1023px) {
        width: 90%;
      }

      &_title {
        color: color(grey);
        font-size: 24px;
        font-family: font(title);
        line-height: 26px;
      }

      &_description {
        margin-top: 20px;

        p {
          font-size: 16px;
          line-height: 22px;
        }
      }

      &_cta {
        margin-top: 30px;
        text-align: right;

        button {
          min-width: 100px;
          border-radius: 8px;
          height: 48px;
          padding-top: 2px;
          padding-bottom: 0;
        }
      }
    }
  }
}