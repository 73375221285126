#page-home {
	position: relative;
	width: 100%;

	.page-title {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0;
		visibility: hidden;
		opacity: 0;
	}

	/** Section hero. */
	.section-hero {
		position: relative;
		width: 100%;

		.section-title {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			opacity: 0;
		}

		.slide {
			position: relative;
			width: 100%;
			height: 400px;
			z-index: 1;

			/** Mobile. */
			@media (max-width: 1023px) {
				height: 580px;
			}

			&-items {
				position: relative;
				width: 100%;
				height: 100%;

				.item {
					position: relative;
					width: 100%;
					height: 400px;

					/** Mobile. */
					@media (max-width: 1023px) {
						height: 450px;
					}

					&-image {
						background-color: black;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 1;

						img {
							opacity: 0.6;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					&-wrapper {
						display: flex;
						align-items: center;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 2;

						&--title {
							color: white;
							font-family: font(title);
							font-size: 48px;
							font-weight: 900;
							line-height: 1.1;
							opacity: 0;
							transition: opacity time(default) ease(inout);

							/** Mobile. */
							@media (max-width: 1023px) {
								font-size: 32px;
							}
						}

						&--subtitle {
							position: relative;
							margin-top: 25px;

							span {
								color: white;
								font-family: font(title);
								font-size: 24px;
								font-weight: normal;
								display: inline-block;
								opacity: 0;
								transform: translateX(0);
								transition: opacity time(default) ease(inout), transform time(default) ease(inout);

								/** Mobile. */
								@media (max-width: 1023px) {
									font-size: 18px;
								}
							}
						}

						&--cta {
							margin-top: 30px;
							opacity: 0;
							transition: opacity time(default) ease(inout);

							.button span {
								font-weight: bold;
							}

							/** Mobile. */
							@media (max-width: 1023px) {
								margin-top: 35px;
							}
						}
					}
				}

				.owl-item.active .item .item-wrapper {
					&--title {
						opacity: 1;
						transition-delay: .3s;
					}

					&--subtitle {
						span {
							opacity: 1;
							transform: translateX(0);
							transition-delay: .45s;
						}
					}

					&--cta {
						opacity: 1;
						transition-delay: .6s;
					}
				}
			}

			&-navigation {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				bottom: 45px;
				left: 50%;
				transform: translateX(-50%);
				width: 980px;
				z-index: 2;

				/** Mobile. */
				@media (max-width: 1023px) {
					bottom: 20px;
					width: 100%;
					flex-direction: column;
					align-items: center;
				}

				.slide-count {
					display: flex;
					align-items: center;
					flex-shrink: 0;

					span {
						color: white;
						font-size: 12px;
						font-family: font(title);
						font-weight: normal;
						display: inline-block;
						width: 15px;
						text-align: center;
						
						&:not(:last-child) {
							margin-right: 3px;
						}
					}
				}

				.slide-dots {
					display: flex;
					align-items: center;
					flex-grow: 1;
					margin: 0 30px;
					max-width: 350px;
					pointer-events: none;

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 90%;
						margin-top: 15px;
					}

					.owl-dot {
						background-color: fade-out(white, .7);
						position: relative;
						flex-grow: 1;
						height: 1px;

						&:not(:last-child) {
							margin-right: 8px;
						}

						&::after {
							content: '';
							background-color: white;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							transform: scaleX(0);
							transform-origin: 100% 50%;
							transition: transform 1s ease(inout);
							z-index: 1;
						}

						&.active {
							&::after {
								transform-origin: 0% 50%;
								transform: scaleX(1);
								transition: transform 7s ease(inout);
							}
						}
					}
				}

				.slide-arrows {
					display: flex;
					align-items: center;
					flex-shrink: 0;

					/** Mobile. */
					@media (max-width: 1023px) {
						display: none;
					}

					.arrow {
						background-color: fade-out(white, .75);
						position: relative;
						width: 40px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						overflow: hidden;
						transition: background-color time(default) ease(inout);

						&:nth-child(1) {
							padding-right: 2px;
							margin-right: 20px;
						}
						&:nth-child(2) {
							padding-left: 2px;
						}

						svg path {
							fill: white;
							transition: fill time(default) ease(inout);
						}

						&:hover {
							background-color: white;

							svg path {
								fill: color(orange);
							}
						}
					}
				}
			}
		}

		.scroll-down {
			top: unset;
			bottom: 110px;

			/** Mobile. */
			@media (max-width: 1023px) {
				display: none;
			}
		}
	}

	/** Section about. */
	.section-about {
		position: relative;
		padding: 70px 0 115px;
		z-index: 1;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 0 0 80px;
		}

		.headline {
			text-align: center;
			width: 650px;
			margin: 0 auto 60px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
			
			&-title {
				color: black;
				font-family: font(title);
				font-size: 36px;
				font-weight: bold;
				line-height: 1.3;
				width: 500px;
				margin: 0 auto 20px;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 24px;
					width: 100%;
					margin: 0 0 20px;
				}
			}

			&-description p {
				color: black;
				font-size: 16px;
				font-weight: normal;
				line-height: 1.7;
			}
		}

		.flex-wrapper {
			.item {
				display: flex;

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-wrap: wrap;
				}

				&:not(:last-child) {
					margin-bottom: 60px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-bottom: 40px;
					}
				}

				&-image {
					width: 50%;
					height: 400px;

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 100%;
						height: 250px;
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				&-content {
					background-color: #F4F4F5;
					width: 50%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					padding: 0 60px;

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 100%;
						padding: 50px 25px;
					}

					&--title {
						color: black;
						font-size: 28px;
						font-weight: bold;
					}

					&--description {
						margin: 10px 0 20px;

						p {
							color: black;
							font-size: 16px;
							font-weight: normal;
							line-height: 1.7;
							white-space: pre-wrap;
						}
					}

					&--cta .button span {
						font-weight: bold;
					}
				}
				
				/** Mobile. */
				@media (min-width: 1024px) {
					&:nth-child(odd) {
						.item-image {
							order: 2;
						}

						.item-content {
							order: 1;
						}
					}
				}
			}
		}
	}

	/** Section blocks. */
	.section-blocks {
		margin-top: -150px;
		padding-top: 250px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding-top: 100px;
			margin: 0;
		}
	}
}