#main-menu {
	background-color: white;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	overflow: auto;
	padding: 0 0 50px;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

	/** Active. */
	&.active {
		opacity: 1;
		visibility: visible;
		pointer-events: initial;
	}

	.headline {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 80px;

		span {
			color: color(orange);
			font-size: 24px;
			font-family: font(title);
			font-weight: normal;
		}

		svg path {
			fill: color(orange);
		}
	}

	.accordions .accordion {
		&-header span br {
			display: none;
		}

		&-link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 20px 60px 20px 0;

			span {
				color: color(grey);
				font-size: 24px;
				font-family: font(title);
				font-weight: normal;
				display: inline-block;
				text-align: left;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 16px;
				}

				br {
					display: none;
				}
			}

			svg {
				position: absolute;
				top: 50%;
				transform: translateY(-50%) rotate(-90deg);
				right: 35px;
				transition: transform time(default) ease(inout);

				/** Mobile. */
				@media (max-width: 1023px) {
					right: 0;
				}

				path {
					stroke: color(orange);
				}
			}
		}

		.sub-menu {
			display: block;

			&::before {
				content: '';
				background-color: #F7F7F7;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				transform-origin: 50% 0%;
				transform: scaleY(0);
				transition: transform time(default) ease(inout);
				box-shadow: 0px 80px 80px 0px rgba(35, 31, 32, 0.5);
				transition-delay: .3s;
				z-index: 1;
			}

			ul {
				position: relative;
				z-index: 2;

				&:not(:last-child) {
					padding-bottom: 25px;
					margin-bottom: 25px;

					&::after {
						content: '';
						background-color: #231F20;
						position: absolute;
						right: 0;
						bottom: 0;
						left: 0;
						height: 1px;
					}
				}

				li {
					&.list-title span {
						color: color(grey);
						font-size: 18px;
						font-family: font(title);
						font-weight: normal;
						display: inline-block;
					}

					a {
						color: #231F20;
						font-size: 16px;
						font-weight: normal;
						display: inline-block;
						position: relative;

						br {
							display: none;
						}

						svg {
							display: none;
						}
					}

					&:nth-child(1) {
						margin-bottom: 30px;
					}

					&:not(:last-child) {
						margin-bottom: 20px;
					}
				}
			}
		}
	}

	.search {
		margin-top: 40px;
		
		form {
			position: relative;
			background-image: linear-gradient(to right, #EA6300, #F47A20);
			border-radius: 50px;

			input {
				background-color: transparent;
				color: white;
				font-size: 18px;
				font-weight: normal;
				padding: 15px 25px;
				width: 350px;
				border: none;

				&::placeholder {
					color: white;
				}
			}

			button {
				position: absolute;
				top: calc(50% + 1px);
				transform: translateY(-50%);
				right: 10px;
				z-index: 2;
			}
		}
	}
}