#page-mortgage-forms {

	/** Section page hero. */
	.section-page-hero .wrapper .wrapper-title {
		width: 100%;
	}

	/** Section content. */
	.section-content {
		padding: 85px 0 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 0;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.description {
			margin-bottom: 60px;
		}

		.question {
			margin-top: 60px;
			margin-bottom: 85px;

			&-title {
				color: #231F20;
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 30px;
			}
		}

		.cta {
			background-color: #4E4D4E;
			text-align: center;
			padding: 75px 25px;

			&-title {
				color: white;
				font-size: 32px;
				font-family: font(title);
				font-weight: normal;
				margin-bottom: 35px;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 24px;
				}
			}
		}
	}
}