#page-resource-centre {
	/** Section page hero. */
	.section-page-hero .wrapper .wrapper-title {
		width: 100%;
	}

	/** Section rates. */
	.section-rates {
		position: relative;
		width: 100%;
		padding: 55px 0 110px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.title {
			color: color(grey);
			font-size: 32px;
			font-family: font(title);
			font-weight: normal;

			/** Mobile. */
			@media (max-width: 1023px) {
				font-size: 24px;
			}
		}

		.navigation {
			margin-top: 35px;

			ul {
				display: flex;

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-wrap: wrap;
				}

				li {
					position: relative;
					width: calc(33.33% - 20px);

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 100%;
					}

					&:not(:last-child) {
						margin-right: 30px;

						/** Mobile. */
						@media (max-width: 1023px) {
							margin-right: 0;
						}
					}

					&::before {
						content: "";
						background-color: #f3f3f3;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 2;
						transition: background-color time(default) ease(inout);
					}

					&::after {
						content: "";
						position: absolute;
						bottom: -21px;
						left: calc(50% - 20px);
						opacity: 0;
						transform: translateY(-21px);
						transition: opacity time(default) ease(inout),
							transform time(default) ease(inout);
						width: 1px;
						height: 1px;
						border-top: 20px solid color(orange);
						border-right: 20px solid transparent;
						border-left: 20px solid transparent;

						/** Mobile. */
						@media (max-width: 1023px) {
							display: none;
						}
					}

					button {
						color: color(grey);
						font-family: font(title);
						font-size: 24px;
						font-weight: normal;
						position: relative;
						z-index: 3;
						width: 100%;
						height: 80px;
						transition: color time(default) ease(inout);

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 18px;
						}
					}

					&.active,
					&:hover {
						&::before {
							background-color: color(orange);
						}

						button {
							color: white;
						}
					}

					&.active {
						&::after {
							opacity: 1;
							transform: translateY(0);
						}
					}
				}
			}
		}

		.tabs {
			position: relative;
			width: 100%;
			margin: 50px auto 0;
			transition: height time(default) ease(inout);

			/** Mobile. */
			@media (max-width: 1023px) {
				margin: 40px 0 0;
			}

			.tab-content {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				z-index: 1;
				opacity: 0;
				visibility: hidden;
				transition: opacity time(default) ease(inout),
					visibility time(default) ease(inout);

				&.active {
					opacity: 1;
					visibility: visible;
					z-index: 2;
					transition-delay: 0.3s;
				}

				&--description {
					margin-bottom: 45px;

					p {
						color: #231f20;
						font-size: 18px;
						font-weight: normal;
						line-height: 1.4;

						/** Mobile. */
						@media (max-width: 1023px) {
							br {
								display: none;
							}
						}
					}
				}

				&--title {
					color: #231f20;
					font-size: 24px;
					font-family: font(title);
					font-weight: normal;
					margin-bottom: 35px;

					/** Mobile. */
					@media (max-width: 1023px) {
						font-size: 21px;
					}
				}

				&--subtitle {
					color: color(grey);
					font-size: 18px;
					font-family: font(title);
					font-weight: normal;
					margin-bottom: 20px;
				}

				.line {
					background-color: #f3f3f3;
					width: 100%;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 35px;
					margin-bottom: 60px;

					/** Mobile. */
					@media (max-width: 1023px) {
						flex-direction: column;
						justify-content: center;
						padding: 20px 25px;
						height: auto;
					}

					&-label {
						color: color(grey);
						font-size: 18px;
						font-family: font(title);
						font-weight: normal;

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 16px;
							text-align: center;
							width: 100%;
							margin-bottom: 20px;
						}
					}

					&-value {
						color: color(grey);
						font-size: 18px;
						font-family: font(title);
						font-weight: normal;
					}
				}

				&--table {
					display: flex;
					margin-bottom: 25px;

					/** Mobile. */
					@media (max-width: 1023px) {
						flex-wrap: wrap;
						max-width: 100%;
						overflow-x: auto;
						white-space: nowrap;
					}

					table {
						width: 100%;
						border: none;

						tr {
							background-color: #f3f3f3;
							color: #231f20;
							border: none;

							&:nth-child(1) {
								background-color: color(grey);
								color: white;
								border-bottom: 2px solid white;
							}

							th {
								border: none;
							}

							td {
								font-size: 18px;
								font-family: font(title);
								font-weight: normal;
								height: 40px;
								text-align: center;
								padding: 0 10px;
								border: none;

								&:nth-child(1) {
									background-color: color(orange);
									font-size: 18.66px;
									font-weight: bold;
									color: white;

									strong {
										font-size: 18.66px;
									}
								}

								strong {
									font-size: 14px;
									margin-right: 10px;
								}
							}
						}
					}

					.column {
						flex-grow: 1;

						/** Mobile. */
						@media (max-width: 1023px) {
							flex-grow: unset;
							width: 100%;
						}

						&:not(:last-child) {
							border-right: 2px solid white;

							/** Mobile. */
							@media (max-width: 1023px) {
								border: none;
								border-bottom: 2px solid white;
							}
						}

						.item {
							font-size: 18px;
							font-family: font(title);
							font-weight: normal;
							width: 100%;
							height: 40px;
							display: flex;
							align-items: center;
							justify-content: center;

							span {
								font-size: 14px;
								margin-right: 10px;
							}

							&:nth-child(1) {
								background-color: color(grey);
								color: white;
								border-bottom: 2px solid white;
							}

							&:nth-child(2) {
								background-color: #f3f3f3;
								color: #231f20;
							}
						}

						/** Mobile. */
						@media (max-width: 1023px) {
							&:nth-child(even) {
								background-color: color(grey);

								.item {
									background-color: transparent;
									border: none;
									color: white;
								}
							}

							&:nth-child(odd) {
								background-color: #f3f3f3;

								.item {
									background-color: transparent;
									color: #231f20;
									border: none;
								}
							}
						}

						&:nth-child(1) {
							/** Mobile. */
							@media (max-width: 1023px) {
								display: none;
							}

							.item {
								background-color: color(orange);
								color: white;
							}
						}
					}
				}

				.buttons {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					a {
						&:not(:last-child) {
							margin-bottom: 30px;
						}
					}
				}
			}
		}

		.buttons {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-top: 50px;

			a {
				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}
		}
	}

	/** Section tools. */
	.section-tools {
		background-color: color(grey);
		padding: 80px 0 110px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.title {
			color: white;
			font-size: 32px;
			font-family: font(title);
			font-weight: normal;

			/** Mobile. */
			@media (max-width: 1023px) {
				font-size: 24px;
			}
		}

		.navigation {
			margin-top: 35px;

			ul {
				display: flex;

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-wrap: wrap;
				}

				li {
					position: relative;
					width: calc(33.33% - 20px);

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 100%;
					}

					&:not(:last-child) {
						margin-right: 30px;

						/** Mobile. */
						@media (max-width: 1023px) {
							margin-right: 0;
						}
					}

					&::before {
						content: "";
						background-color: #f3f3f3;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 2;
						transition: background-color time(default) ease(inout);
					}

					&::after {
						content: "";
						position: absolute;
						bottom: -21px;
						left: calc(50% - 20px);
						opacity: 0;
						transform: translateY(-21px);
						transition: opacity time(default) ease(inout),
							transform time(default) ease(inout);
						width: 1px;
						height: 1px;
						border-top: 20px solid color(orange2);
						border-right: 20px solid transparent;
						border-left: 20px solid transparent;

						/** Mobile. */
						@media (max-width: 1023px) {
							display: none;
						}
					}

					button {
						color: color(grey);
						font-family: font(title);
						font-size: 24px;
						font-weight: normal;
						position: relative;
						z-index: 3;
						width: 100%;
						height: 80px;
						transition: color time(default) ease(inout);

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 18px;
						}
					}

					&.active,
					&:hover {
						&::before {
							background-color: color(orange2);
						}

						button {
							color: color(grey);
						}
					}

					&.active {
						&::after {
							opacity: 1;
							transform: translateY(0);
						}
					}
				}
			}
		}

		.tabs {
			position: relative;
			margin: 80px auto 0;
			transition: height time(default) ease(inout);

			.tab-content {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				z-index: 1;
				opacity: 0;
				visibility: hidden;
				transition: opacity time(default) ease(inout),
					visibility time(default) ease(inout);

				&.active {
					opacity: 1;
					visibility: visible;
					transition-delay: 0.3s;
					z-index: 2;
				}

				.flex-wrapper {
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.item {
						position: relative;
						width: 277px;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						height: 170px;
						border: 1px solid white;
						transition: background-color time(default) ease(inout),
							box-shadow time(default) ease(inout);

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 100%;
							height: auto;
							padding: 40px 20px;
						}

						&:not(:last-child) {
							margin-right: 30px;

							/** Mobile. */
							@media (max-width: 1023px) {
								margin: 0 0 30px;
							}
						}

						&-link {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: 2;
						}

						&-title {
							color: white;
							font-size: 24px;
							font-family: font(title);
							font-weight: normal;
							line-height: 1.5;
							width: 100%;
							text-align: center;
							padding: 0 20px;
							transition: color time(default) ease(inout);
						}

						&:hover {
							background-color: white;
							box-shadow: 0px 40px 80px 0px rgba(35, 31, 32, 0.5);

							.item-title {
								color: #231f20;
							}
						}
					}
				}

				.accordions .accordion {
					border-color: color(orange2);

					&-header {
						span {
							color: white;
						}

						svg path {
							stroke: color(orange2);
						}
					}

					&-wrapper--content {
						color: white;

						p {
							color: white;
						}

						a {
							color: color(orange2);
							border-color: color(orange2);
							transition: color time(default) ease(inout);

							&:hover {
								color: white;
							}
						}

						ul li {
							color: white;
						}
					}
				}

				.wrapper {
					&-title {
						color: white;
						font-size: 24px;
						font-family: font(title);
						font-weight: normal;
						margin-bottom: 25px;
					}

					&-description p {
						color: white;
					}

					&-cta {
						margin-top: 20px;
					}
				}
			}
		}
	}
}
