#page-applications-centre {

	/** Section page hero. */
	.section-page-hero .wrapper .wrapper-title {
		width: 850px;

		/** Mobile. */
		@media (max-width: 1023px) {
			width: 100%;
		}
	}

	/** Section content. */
	.section-content {
		padding: 85px 0 130px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.description {
			margin-bottom: 120px;

			/** Mobile. */
			@media (max-width: 1023px) {
				margin-bottom: 60px;
			}
		}

		.cta {
			text-align: center;
			

			&-title {
				color: #231F20;
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 70px;

				/** Mobile. */
				@media (max-width: 1023px) {
					margin-bottom: 30px;
				}
			}

			&-buttons {
				display: flex;
				align-items: center;
				justify-content: center;

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-wrap: wrap;
				}

				a {

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 100%;
						display: block;
						text-align: center;
					}

					&:not(:last-child) {
						margin-right: 40px;

						/** Mobile. */
						@media (max-width: 1023px) {
							margin: 0 0 40px;
						}
					}
				}
			}
		}
	}
}