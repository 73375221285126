#page-what-is-changing {
	
	/** Section content. */
	.section-content {
		padding: 80px 0 150px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.wrapper {
			margin-top: 75px;

			.item {
				&:not(:last-child) {
					margin-bottom: 75px;
				}

				&-title {
					color: color(orange);
					font-size: 24px;
					font-family: font(title);
					font-weight: normal;
					margin-bottom: 5px;

					/** Mobile. */
					@media (max-width: 1023px) {
						font-size: 18px;
					}
				}

				&-subtitle {
					color: color(grey);
					font-size: 18px;
					font-weight: bold;
					margin-bottom: 50px;
				}
			}
		}

		.faqs {
			margin-top: 85px;

			&-title {
				color: color(orange);
				font-size: 24px;
				font-family: font(title);
				font-weight: normal;
				margin-bottom: 10px;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 18px;
				}
			}

			.accordions {
				margin-top: 45px;
			}
		}
	}

	/** Section cta. */
	.section-cta {
		background-color: #4E4D4E;
		padding: 100px 25px;
		text-align: center;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 25px;
		}

		.title {
			color: white;
			font-size: 24px;
			font-family: font(title);
			font-weight: normal;
			line-height: 1.4;

			/** Mobile. */
			@media (max-width: 1023px) {
				font-size: 18px;
			}
		}

		.buttons {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 40px;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			a {

				/** Mobile. */
				@media (max-width: 1023px) {
					display: block;
					width: 100%;
				}

				&:not(:last-child) {
					margin-right: 30px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin: 0 0 15px;	
					}
				}
			}
		}
	}
}