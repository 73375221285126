#page-privacy {

	/** Section content. */
	.section-content {
		padding: 85px 0 110px;

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.description {
			margin-bottom: 90px;
		}
	}
}