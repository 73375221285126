.section-page-hero {
	position: relative;
	width: 100%;
	height: 400px;

	/** Mobile. */
	@media (max-width: 1023px) {
		height: auto;
	}

	.background {
		background-color: black;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		&::after {
			content: '';
			background-image: linear-gradient(to top, black 10%, fade-out(black, 1));
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: .5;
			z-index: 2;
		}

		img {
			position: relative;
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: .5;
			z-index: 1;
		}
	}

	.wrapper {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
		padding: 60px 0 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			position: relative;
			top: unset;
			right: unset;
			bottom: unset;
			left: unset;
			padding: 60px 0;
		}

		&-breadcrumbs {

			/** Mobile. */
			@media (max-width: 1023px) {
				display: none;
				margin-bottom: 70px;
			}

			ul {
				display: flex;
				align-items: center;

				li {
					position: relative;

					&:not(:last-child) {
						&::after {
							content: '>';
							color: color(orange2);
							font-size: 16px;
							font-weight: normal;
							margin: 0 7px;
						}
					}
					
					a,
					span {
						font-size: 16px;
						font-weight: normal;
						display: inline-block;
					}

					span {
						color: white;
					}

					a {
						color: color(orange2);
						transition: color time(default) ease(inout);

						&:hover {
							color: white;
						}
					}
				}
			}
		}

		&-title {
			color: white;
			font-size: 60px;
			font-family: font(title);
			font-weight: 900;
			line-height: 1.2;
			margin-top: 70px;
			width: 710px;

			/** Mobile. */
			@media (max-width: 1023px) {
				font-size: 32px;
				width: 100%;
				margin-top: 0;
			}
		}

		&-subtitle {
			position: relative;
			width: 585px;
			margin-top: 40px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
				padding: 0;
			}

			p {
				color: white;
				font-size: 28px;
				font-family: font(title);
				font-weight: normal;
				line-height: 1.2;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 21px;
				}
			}
		}

		&-description {
			position: relative;
			width: 585px;
			margin-top: 45px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
				padding: 0;
			}

			p {
				color: white;
				font-size: 18px;
				font-weight: normal;
				line-height: 1.4;
			}
		}
	}
}