#page-browser-requirements {

	/** Section content. */
	.section-content {
		padding: 80px 0 150px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.supported {
			&-title {
				color: color(orange);
				font-size: 36px;
				font-family: font(title);
				font-weight: normal;
				margin-bottom: 30px;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 24px;
				}
			}

			&-browsers {
				margin: 60px 0;

				.item  {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 18px 0;
					border-bottom: 1px solid color(orange);

					/** Mobile. */
					@media (max-width: 1023px) {
						flex-wrap: wrap;
						justify-content: center;
					}

					&-title {
						color: color(grey);
						font-size: 18px;
						font-family: font(title);
						font-weight: normal;

						/** Mobile. */
						@media (max-width: 1023px) {
							width: 100%;
							text-align: center;
							margin-bottom: 15px;
						}
					}

					&-button {
						a {
							color: color(orange);
							font-size: 18px;
							font-weight: normal;
							border-bottom: 1px solid color(orange);
							transition: color time(default) ease(inout), border-color time(default) ease(inout);

							&:hover {
								color: color(grey);
								border-color: color(grey);
							}
						}
					}
				}
			}
		}

		.wrapper {
			margin-top: 80px;

			.item {
				&:not(:last-child) {
					margin-bottom: 40px;
				}

				&-title {
					color: color(grey);
					font-size: 24px;
					font-family: font(title);
					font-weight: normal;
					margin-bottom: 25px;

					/** Mobile. */
					@media (max-width: 1023px) {
						font-size: 18px;
					}
				}
			}
		}
	}
}