#page-about-us {

	/** Section content. */
	.section-content {
		padding: 80px 0 115px;
		overflow: hidden;
		
		.navigation {
			margin-top: 35px;

			ul {
				display: flex;

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-wrap: wrap;
				}

				li {
					position: relative;
					width: calc(33.33% - 20px);

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 100%;
					}

					&:not(:last-child) {
						margin-right: 30px;

						/** Mobile. */
						@media (max-width: 1023px) {
							margin-right: 0;
						}
					}

					&::before {
						content: '';
						background-color: #F3F3F3;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 2;
						transition: background-color time(default) ease(inout);
					}

					&::after {
						content: '';
						position: absolute;
						bottom: -21px;
						left: calc(50% - 20px);
						opacity: 0;
						transform: translateY(-21px);
						transition: opacity time(default) ease(inout), transform time(default) ease(inout);
						width: 1px;
						height: 1px;
						border-top: 20px solid color(orange);
						border-right: 20px solid transparent;
						border-left: 20px solid transparent;

						/** Mobile. */
						@media (max-width: 1023px) {
							display: none;
						}
					}

					button {
						color: color(grey);
						font-family: font(title);
						font-size: 24px;
						font-weight: normal;
						position: relative;
						z-index: 3;
						width: 100%;
						height: 80px;
						transition: color time(default) ease(inout);

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 18px;
						}
					}

					&.active,
					&:hover {
						&::before {
							background-color: color(orange);
						}

						button {
							color: white;
						}
					}

					&.active {
						&::after {
							opacity: 1;
							transform: translateY(0);
						}
					}
				}
			}
		}

		.tabs {
			position: relative;
			width: 970px;
			margin: 0 auto;
			transition: height time(default) ease(inout);

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}

			.tab-content {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				z-index: 1;
				opacity: 0;
				visibility: hidden;
				transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

				&.active {
					opacity: 1;
					visibility: visible;
					z-index: 2;
					transition-delay: .3s;
				}

				.quick {
					margin: 85px 0 130px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin: 60px 0 70px;
					}

					&-title {
						color: color(grey);
						font-size: 24px;
						font-family: font(title);
						font-weight: normal;
						margin-bottom: 25px;

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 18px;
							line-height: 1.2;
						}
					}
				}

				.wrapper {
					&-title {
						color: color(grey);
						font-size: 36px;
						font-family: font(title);
						font-weight: normal;
						margin-bottom: 55px;

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 24px;
							margin-bottom: 30px;
						}
					}
				}

				.list {
					&-title {
						color: color(grey);
						font-size: 36px;
						font-family: font(title);
						font-weight: normal;
						margin: 70px 0 45px;

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 24px;
							margin: 50px 0 30px;
						}
					}

					&-items .item {
						display: flex;
						align-items: center;

						/** Mobile. */
						@media (max-width: 1023px) {
							flex-wrap: wrap;
						}

						&:not(:last-child) {
							margin-bottom: 75px;

							/** Mobile. */
							@media (max-width: 1023px) {
								margin-bottom: 50px;
							}
						}

						/** Desktop. */
						@media (min-width: 1024px) {
							&:nth-child(odd) {
								.item-image {
									order: 1;
									margin-right: 60px;
									clip-path: polygon(0 0, calc(100% - 15px) 0, calc(100% - 15px) calc(50% - 15px), 100% 50%, calc(100% - 15px) calc(50% + 15px), calc(100% - 15px) 100%, 0 100%);
								}

								.item-content {
									order: 2;
								}
							}

							&:nth-child(even) {
								.item-image {
									order: 2;
									margin-left: 60px;
									clip-path: polygon(100% 0, 100% 100%, 15px 100%, 15px calc(50% + 15px), 0 50%, 15px calc(50% - 15px), 15px 0);
								}

								.item-content {
									order: 1;
								}
							}
						}

						&-image {
							position: relative;
							width: 485px;
							height: 340px;
							flex-shrink: 0;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}

							/** Mobile. */
							@media (max-width: 1023px) {
								width: 100%;
								height: auto;
								margin-bottom: 30px;

								img {
									width: 100%;
									height: auto;
								}
							}
						}

						&-content {
							flex-grow: 1;

							&--title {
								color: #231F20;
								font-size: 20px;
								font-family: font(title);
								font-weight: normal;
								margin-bottom: 5px;
							}

							&--subtitle {
								color: color(grey);
								font-size: 18px;
								font-weight: bold;
								margin-bottom: 40px;

								/** Mobile. */
								@media (max-width: 1023px) {
									margin-bottom: 25px;
								}
							}
						}
					}
				}

				.opportunities {
					margin-top: 140px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-top: 70px;
					}

					&.small {
						margin-top: 95px;
					}

					&-title {
						color: color(grey);
						font-size: 36px;
						font-family: font(title);
						font-weight: normal;
						margin-bottom: 45px;

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 24px;
							margin-bottom: 30px;
						}
					}

					&-list {
						.item {
							position: relative;
							padding: 15px 25px;
							border-bottom: 1px solid color(orange);
							transition: background-color time(default) ease(inout);

							/** Mobile. */
							@media (max-width: 1023px) {
								padding: 15px 10px;
							}

							&:hover {
								background-color: #F3F3F3;
							}

							&-link {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								z-index: 5;
							}

							&-title {
								color: color(grey);
								font-size: 24px;
								font-family: font(title);
								font-weight: normal;

								/** Mobile. */
								@media (max-width: 1023px) {
									font-size: 18px;
								}
							}
						}
					}
				}

				.merit {
					margin-top: 125px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin-top: 70px;
					}

					&-title {
						color: color(orange);
						font-size: 36px;
						font-family: font(title);
						font-weight: normal;
						margin-bottom: 20px;

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 24px;
							margin-bottom: 30px;
						}
					}

					&-list {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 75px;

						/** Mobile. */
						@media (max-width: 1023px) {
							flex-wrap: wrap;
						}

						.item {
							background-color: #231F20;
							position: relative;
							width: calc(50% - 15px);
							height: 270px;
							overflow: hidden;

							/** Mobile. */
							@media (max-width: 1023px) {
								width: 100%;
								height: 250px;

								&:not(:last-child) {
									margin-bottom: 30px;
								}
							}

							&-link {
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								z-index: 5;
							}

							&-image {
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								z-index: 1;
								opacity: .5;
								transform-origin: 50% 50%;
								transition: transform time(default) ease(inout);

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}

							&-content {
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								z-index: 2;
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								justify-content: flex-end;
								padding: 0 0 25px 30px;

								/** Mobile. */
								@media (max-width: 1023px) {
									padding: 0 0 15px 15px;
								}

								&--title {
									color: white;
									font-size: 24px;
									font-family: font(title);
									font-weight: normal;
									padding-right: 140px;

									/** Mobile. */
									@media (max-width: 1023px) {
										font-size: 18px;
										padding-right: 0;
									}
								}

								&--subtitle {
									color: white;
									font-size: 18px;
									font-family: font(title);
									font-weight: normal;
									margin-top: 15px;

									/** Mobile. */
									@media (max-width: 1023px) {
										font-size: 16px;
									}
								}
							}

							&:hover .item-image {
								transform: scale(1.05);
							}
						}
					}
				}

				.tabs-opportunities {
					background-color: white;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					opacity: 0;
					visibility: hidden;
					z-index: 5;
					transition: height time(default) ease(inout), opacity time(default) ease(inout), visibility time(default) ease(inout);

					&.active {
						opacity: 1;
						visibility: visible;
					}

					.tab-opportunity {
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						z-index: 1;
						opacity: 0;
						visibility: hidden;
						transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

						&.active {
							opacity: 1;
							visibility: visible;
							z-index: 2;
							transition-delay: .3s;
						}

						&--title {
							color: color(orange);
							font-size: 36px;
							font-family: font(title);
							font-weight: normal;
							margin-bottom: 35px;

							/** Mobile. */
							@media (max-width: 1023px) {
								font-size: 24px;
								margin-bottom: 30px;
							}
						}

						&--wrapper {
							.opportunity-title {
								color: color(orange);
								font-size: 18px;
								font-family: font(title);
								font-weight: normal;
							}

							.opportunity-description {
								margin-top: 35px;

								strong {
									color: color(grey);
								}
							}

							&:not(:last-child) {
								margin-bottom: 75px;
							}
						}

						.apply {
							&-title {
								color: color(orange);
								font-size: 18px;
								font-family: font(title);
								font-weight: normal;
								margin-bottom: 10px;
							}
						}

						.linkedin {
							margin: 40px 0 45px;
						}

						.return {
							margin-top: 40px;
						}
					}
				}
			}
		}
	}
}