#page-mortgage-administration {
	/** Section content. */
	.section-content {
		padding: 80px 0 150px;
		overflow: hidden;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.navigation {
			margin-top: 35px;

			ul {
				display: flex;

				/** Mobile. */
				@media (max-width: 1023px) {
					flex-wrap: wrap;
				}

				li {
					position: relative;
					width: calc(50% - 15px);

					/** Mobile. */
					@media (max-width: 1023px) {
						width: 100%;
					}

					&:not(:last-child) {
						margin-right: 30px;

						/** Mobile. */
						@media (max-width: 1023px) {
							margin-right: 0;
						}
					}

					&::before {
						content: "";
						background-color: #f3f3f3;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 2;
						transition: background-color time(default) ease(inout);
					}

					&::after {
						content: "";
						position: absolute;
						bottom: -21px;
						left: calc(50% - 20px);
						opacity: 0;
						transform: translateY(-21px);
						transition: opacity time(default) ease(inout),
							transform time(default) ease(inout);
						width: 1px;
						height: 1px;
						border-top: 20px solid color(orange);
						border-right: 20px solid transparent;
						border-left: 20px solid transparent;

						/** Mobile. */
						@media (max-width: 1023px) {
							display: none;
						}
					}

					button {
						color: color(grey);
						font-family: font(title);
						font-size: 24px;
						font-weight: normal;
						position: relative;
						z-index: 3;
						width: 100%;
						height: 80px;
						transition: color time(default) ease(inout);

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 18px;
						}
					}

					&.active,
					&:hover {
						&::before {
							background-color: color(orange);
						}

						button {
							color: white;
						}
					}

					&.active {
						&::after {
							opacity: 1;
							transform: translateY(0);
						}
					}
				}
			}
		}

		.tabs {
			position: relative;
			width: 970px;
			margin: 60px auto 0;
			transition: height time(default) ease(inout);

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
				margin: 50px 0 0;
			}

			.tab-content {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				z-index: 1;
				opacity: 0;
				visibility: hidden;
				transition: opacity time(default) ease(inout),
					visibility time(default) ease(inout);

				&.active {
					opacity: 1;
					visibility: visible;
					z-index: 2;
					transition-delay: 0.3s;
				}

				.wrapper {
					&:not(:last-child) {
						margin-bottom: 85px;
					}

					&-title {
						color: color(orange);
						font-size: 36px;
						font-family: font(title);
						font-weight: normal;
						margin-bottom: 30px;

						/** Mobile. */
						@media (max-width: 1023px) {
							font-size: 24px;
						}
					}
				}

				.accordions {
					margin-top: 65px;

					.table {
						display: flex;
						align-items: flex-start;
						margin-top: 40px;

						/** Mobile. */
						@media (max-width: 1023px) {
							border: 1px solid color(orange);
							flex-wrap: wrap;
						}

						.item {
							flex-grow: 1;

							/** Mobile. */
							@media (max-width: 1023px) {
								width: 100%;

								&:not(:last-child) {
									margin-bottom: 30px;
								}
							}

							&:not(:last-child) {
								border-right: 1px solid white;

								/** Mobile. */
								@media (max-width: 1023px) {
									border: none;
								}
							}

							&-title {
								background-color: color(orange);
								color: white;
								font-size: 18.66px;
								font-family: font(title);
								font-weight: bold;
								display: flex;
								align-items: center;
								justify-content: center;
								text-align: center;
								height: 45px;
							}

							&-description {
								padding: 30px 40px 0;
							}
						}
					}
				}
			}
		}
	}
}
