#page-registered-account-forms {

	/** Section page hero. */
	.section-page-hero .wrapper .wrapper-title {
		width: 100%;
	}

	/** Section content. */
	.section-content {
		padding: 80px 0 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 0;
		}

		.container {
			width: 970px;

			&.full {
				width: 100%;
			  max-width: 1200px;
			}

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

    .buttons {
      display: flex;
      align-items: center;
      margin-bottom: 50px;

      /** Mobile. */
      @media (max-width: 767px) {
        flex-direction: column;
      }

      a {
        &:not(:last-child) {
          margin-right: 30px;

          /** Mobile. */
          @media (max-width: 767px) {
            margin: 0 0 30px;
          }
        }
      }
    }

    .blocks {
      .block {
        &:not(:last-child) {
          margin-bottom: 65px;
        }

        &-title {
          color: color(grey);
          font-size: 24px;
          font-family: font(title);
          font-weight: normal;
          margin-bottom: 25px;
        }

        &-cta {
          margin-top: 20px;
        }
      }
    }

    .message {
      background-color: color(grey);
      color: white;
      font-size: 16px;
      font-weight: normal;
      width: calc(100% - 50px);
      margin: 40px auto 0;
      padding: 15px 25px;
      line-height: 1.4;
    }

		.supplementary {
			margin-top: 80px;

			&-title {
				color: color(grey);
				font-size: 24px;
				font-family: font(title);
				font-weight: normal;
				margin-bottom: 30px;
			}
		}

		.question {
			margin-top: 70px;

			&-title {
				color: color(grey);
        font-size: 24px;
        font-family: font(title);
        font-weight: normal;
        margin-bottom: 25px;
			}
		}

		.cta {
			background-color: #4E4D4E;
			text-align: center;
			margin-top: 85px;
			padding: 75px 25px;

			&-title {
				color: white;
				font-size: 32px;
				font-family: font(title);
				font-weight: normal;
				margin-bottom: 35px;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 24px;
				}
			}
		}
	}
}