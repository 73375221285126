#page-our-community {

	/** Section content. */
	.section-content {
		padding: 80px 0 150px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-top: 120px;

			.item {
				width: 370px;

				&-title {
					color: color(orange);
					font-size: 36px;
					font-family: font(title);
					font-weight: normal;
				}

				&-description {
					margin: 20px 0 15px;
				}
			}
		}
	}
}