#main-header {
	background-color: white;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 50;
	padding: 0;
	transition: box-shadow time(default) ease(inout);

	/** Mobile. */
	@media (max-width: 1200px) {
		padding: 10px 0;
	}

	.flex-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.logo {
			position: relative;
			flex-shrink: 0;
			margin-right: 50px;

			/** Mobile. */
			@media (max-width: 1200px) {
				margin-right: 30px;
			}
			@media (max-width: 1023px) {
				margin-right: 0;
			}

			a {
				display: inline-block;
			}
		}

		.navigation {
			/** Mobile. */
			@media (max-width: 1023px) {
				display: none;
			}

			> ul {
				display: flex;
				align-items: center;

				> li {
					position: relative;
					margin: 0;
					padding: 0;

					> a {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 155px;
						height: 100px;
						cursor: pointer;
						position: relative;
						z-index: 2;

						/** Mobile. */
						@media (max-width: 1500px) {
							width: 130px;
						}
						@media (max-width: 1200px) {
							width: 100px;
						}

						&::before,
						&::after {
							position: absolute;
							opacity: 0;
							transition: opacity time(default) ease(inout);
							z-index: 1;
						}

						&::before {
							content: "";
							background-color: color(grey);
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
						}

						&::after {
							bottom: -16px;
							left: 50%;
							transform: translateX(-15px);
							width: 1px;
							height: 1px;
							border-top: 15px solid color(grey);
							border-left: 15px solid transparent;
							border-right: 15px solid transparent;
						}

						span {
							color: color(grey);
							font-family: font(title);
							font-size: 16px;
							font-weight: normal;
							text-align: center;
							position: relative;
							z-index: 2;
							display: inline-block;
							line-height: 1.3;
							transition: color time(default) ease(inout);

							@media (max-width: 1200px) {
								font-size: 14px;
							}
						}

						&:hover {
							&::before,
							&::after {
								opacity: 1;
							}

							span {
								color: white;
							}
						}
					}

					&.has-children > a {
						&::after {
							content: "";
						}
					}

					.sub-menu {
						display: flex;
						justify-content: center;
						position: fixed;
						top: 100px;
						right: 0;
						left: 0;
						padding: 50px 0 45px;
						opacity: 0;
						transition: opacity time(fast) ease(inout);
						pointer-events: none;
						z-index: 1;

						&::before {
							content: "";
							background-color: #f7f7f7;
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							transform-origin: 50% 0%;
							transform: scaleY(0);
							transition: transform time(default) ease(inout);
							box-shadow: 0px 80px 80px 0px rgba(35, 31, 32, 0.5);
							transition-delay: 0.3s;
							z-index: 1;
						}

						ul {
							position: relative;
							z-index: 2;

							&:not(:last-child) {
								padding-right: 105px;
								margin-right: 50px;

								&::after {
									content: "";
									background-color: #231f20;
									position: absolute;
									top: 0;
									right: 0;
									bottom: 0;
									width: 1px;
									transform-origin: 50% 0%;
									transform: scaleY(0);
									transition: transform time(default) ease(inout);
								}
							}

							li {
								opacity: 0;
								transition: opacity time(slow) ease(inout);

								&.list-title span {
									color: color(grey);
									font-size: 18px;
									font-family: font(title);
									font-weight: normal;
									display: inline-block;
								}

								a {
									color: #231f20;
									font-size: 16px;
									font-weight: normal;
									display: inline-block;
									position: relative;

									svg {
										position: absolute;
										top: calc(50% - 1px);
										right: -35px;
										transform: translate(-10px, -50%);
										opacity: 0;
										transition: opacity time(default) ease(inout),
											transform time(default) ease(inout);

										path {
											fill: color(orange);
										}
									}

									&:hover svg {
										opacity: 1;
										transform: translate(0, -50%);
									}
								}

								&:nth-child(1) {
									margin-bottom: 30px;
								}

								&:not(:last-child) {
									margin-bottom: 20px;
								}
							}
						}
					}

					/** Hover sub menu. */
					&.has-children:hover,
					&.open {
						> a {
							&::before,
							&::after {
								opacity: 1;
							}

							span {
								color: white;
							}
						}

						.sub-menu {
							pointer-events: initial;
							opacity: 1;

							&::before {
								transform: scaleY(1);
								transition-delay: 0s;
							}

							ul {
								li {
									opacity: 1;
								}

								&::after {
									transform: scaleY(1);
								}

								/** @each: Delay. */
								@for $i from 1 to 10 {
									&:nth-child(#{$i}) {
										&::after {
											transition-delay: #{0.1s * $i};
										}

										li {
											transition-delay: #{0.1s * $i};
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.right {
			position: relative;
			display: flex;
			align-items: center;
			z-index: 2;

			/** Mobile. */
			@media (max-width: 1023px) {
				display: none;
			}

			.sign-in {
				position: relative;

				&--box {
					background-color: color(orange);
					width: 165px;
					height: 50px;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 60px;
					padding-bottom: 2px;

					&::after {
						content: "";
						position: absolute;
						bottom: -16px;
						left: 50%;
						transform: translate(-15px, -100%);
						width: 1px;
						height: 1px;
						border-top: 15px solid color(orange);
						border-left: 15px solid transparent;
						border-right: 15px solid transparent;
						opacity: 0;
						transition: opacity time(default) ease(inout),
							transform time(default) ease(inout);
						z-index: 1;
					}

					span {
						color: white;
						font-family: font(title);
						font-size: 18.66px;
						font-weight: bold;
						position: relative;
						margin-right: 14px;
						z-index: 2;
					}

					svg {
						position: relative;
						z-index: 2;

						path {
							stroke: white;
						}
					}
				}

				&--menu {
					position: absolute;
					top: 0;
					width: 100%;
					padding-top: 75px;
					pointer-events: none;

					ul {
						background-color: #f7f7f7;
						padding: 25px 0;
						box-shadow: 0px 25px 32px 0px rgba(35, 31, 32, 0.36);
						opacity: 0;
						transform: translateY(10px);
						transition: opacity time(default) ease(inout),
							transform time(default) ease(inout);

						li {
							display: block;
							position: relative;
							text-align: center;
							opacity: 0;
							transform: translateY(10px);
							transition: opacity time(default) ease(inout),
								transform time(default) ease(inout);

							&:not(:last-child) {
								margin-bottom: 30px;

								&::after {
									content: "";
									background-color: color(orange);
									position: absolute;
									bottom: -15px;
									left: 50%;
									width: 75px;
									height: 1px;
									transform: translateX(-50%) scaleX(0);
									transform-origin: 50% 50%;
									transition: transform time(default) ease(inout);
								}
							}

							a {
								color: color(grey);
								font-family: font(title);
								font-size: 14px;
								font-weight: normal;
								transition: color time(default) ease(inout);

								&:hover {
									color: color(orange);
								}
							}
						}
					}
				}

				&:hover {
					.sign-in--box {
						&::after {
							opacity: 1;
							transform: translate(-15px, 0);
						}
					}

					.sign-in--menu {
						pointer-events: initial;

						ul {
							opacity: 1;
							transform: translateY(0);

							li {
								opacity: 1;
								transform: translateY(0);

								/** @each: Delay. */
								@for $i from 1 to 10 {
									&:nth-child(#{$i}) {
										transition-delay: #{0.1s * $i};

										&::after {
											transform: translateX(-50%) scaleX(1);
											transition-delay: #{0.1s * $i + 0.1s};
										}
									}
								}
							}
						}
					}
				}
			}

			.search {
				position: relative;
				margin-left: 15px;

				button {
					background-color: black;
					position: relative;
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					transition: background-color time(default) ease(inout);

					svg path {
						fill: white;
					}

					&:hover {
						background-color: color(orange);
					}
				}

				&-form {
					position: relative;
					background-image: linear-gradient(to right, #ea6300, #f47a20);
					border-radius: 50px;
					position: absolute;
					top: calc(50% - 1px);
					right: -9px;
					transform: translateY(-50%);
					z-index: 2;
					opacity: 0;
					visibility: hidden;
					transition: opacity time(default) ease(inout),
						visibility time(default) ease(inout);

					input {
						background-color: transparent;
						color: white;
						font-size: 18px;
						font-weight: normal;
						padding: 15px 25px;
						width: 350px;
						border: none;

						&::placeholder {
							color: white;
						}
					}

					button {
						position: absolute;
						top: calc(50% + 1px);
						transform: translateY(-50%);
						right: 10px;
						z-index: 2;
					}
				}

				&.active .search-form {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.menu-toggler {
			position: relative;
			width: 25px;
			height: 20px;

			/** Desktop. */
			@media (min-width: 1023px) {
				display: none;
			}

			span {
				background-color: color(orange);
				position: absolute;
				right: 0;
				left: 0;
				height: 2px;

				&:nth-child(1) {
					top: 0;
				}

				&:nth-child(2) {
					top: calc(50% - 1px);
				}

				&:nth-child(3) {
					bottom: 0;
				}
			}
		}
	}

	/** Sticky. */
	&.sticky {
		box-shadow: 0px 10px 30px 0px rgba(35, 31, 32, 0.2);
	}
}
