#page-residential-mortgages {

	/** Section accordions. */
	.section-accordions {
		padding: 75px 0 115px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.description {
			margin-bottom: 45px;
			padding-right: 90px;

			/** Mobile. */
			@media (max-width: 1023px) {
				padding: 0;
			}
		}
	}

	/** Section blocks. */
	.section-blocks {
		margin-top: -150px;
		padding-top: 250px;

		&.no-cta {
			margin-top: 0;
			padding-top: 100px;
		}

		/** Mobile. */
		@media (max-width: 1023px) {
			margin: 0;
			padding-top: 100px;
		}
	}
}