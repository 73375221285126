//
.link-main-content {
	background-color: color(orange);
	color: white;
	font-size: 18px;
	font-weight: normal;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 170px;
	height: 40px;
	padding: 2px 0 0;
	box-shadow: 0 10px 40px rgba(0, 0, 0, .2);
	transform: translateY(-80px);
	z-index: 400;

	&:focus {
		transform: translateY(0);
	}
}

// Scroll down
// ------------------------------

@keyframes scroll_down {
	0% { transform: scaleX(0); transform-origin: 100% 50%; }
	25.5% { transform: scaleX(1); transform-origin: 100% 50%; }
	25.6% { transform: scaleX(1); transform-origin: 0% 50%; }
	50%, 100% { transform: scaleX(0); transform-origin: 0% 50%; }
}

.scroll-down {
	position: absolute;
	right: -30px;
	padding-left: 60px;
	transform-origin: 50% 50%;
	transform: rotate(-90deg);
	z-index: 2;

	span {
		font-size: 12px;
		font-family: font(title);
		font-weight: normal;
		text-transform: uppercase;
	}

	&::before,
	&::after {
		content: '';
		width: 40px;
		height: 1px;
		position: absolute;
		top: 7px;
		left: 0;
		z-index: 1;
	}

	&::after {
		z-index: 2;
		transform-origin: 0% 50%;
		animation-name: scroll_down;
	  animation-duration: 3s;
	  animation-iteration-count: infinite;
	  animation-timing-function: ease(inout);
	}

	/** Variations [Theme]. */
	&[theme="white"] {
		span {
			color: white;
			opacity: .5;
		}

		&::before {
			opacity: .5;
		}

		&::before,
		&::after {
			background-color: white;
		}
	}

	&[theme="orange"] {
		span {
			color: color(grey);
			opacity: .5;
		}

		&::before {
			background-color: color(grey);
			opacity: .5;
		}

		&::after {
			background-color: color(orange);
		}
	}
}

// Content
// ------------------------------

.content {
	> * {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	p {
		color: color(grey);
		font-size: 18px;
		font-weight: normal;
		line-height: 1.5;
	}

	strong {
		color: color(grey);
	}

	a {
		color: #B45004;
		border-bottom: 1px solid #B45004;
		transition: color time(default) ease(inout), border-color time(default) ease(inout);

		&:hover {
			color: color(grey);
			border-color: color(grey);
		}
	}

	ul {
		display: block;

		li {
			display: block;
			color: color(grey);
			font-size: 18px;
			font-weight: normal;
			line-height: 1.5;
			position: relative;
			padding-left: 20px;

			&:not(:last-child) {
				margin-bottom: 5px;
			}

			&::before {
				content: '';
				background-color: color(grey);
				position: absolute;
				top: 9px;
				left: 0;
				width: 6px;
				height: 6px;
				border-radius: 50%;
			}
		}
	}
}

// Transition slide
// ------------------------------
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-in {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
}
.fade-out {
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}