#page-resolve-your-complaints {

	/** Section accordions. */
	.section-accordions {
		padding: 75px 0 115px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.description {
			margin-bottom: 75px;
		}

		.accordions .accordion-wrapper--content {
			padding-right: 120px;

			/** Mobile. */
			@media (max-width: 1023px) {
				padding-right: 0;
			}
		}
	}
}