.accordions {
	position: relative;
	width: 100%;
	margin: 0;
	padding: 0;

	.accordion {
		position: relative;
		width: 100%;
		border-bottom: 1px solid color(orange);

		&-header {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 20px 60px 20px 0;

			span {
				color: color(grey);
				font-size: 24px;
				font-family: font(title);
				font-weight: normal;
				display: inline-block;
				text-align: left;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 16px;
				}
			}

			svg {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 35px;
				transition: transform time(default) ease(inout);

				/** Mobile. */
				@media (max-width: 1023px) {
					right: 0;
				}

				path {
					stroke: color(orange);
				}
			}
		}

		&-wrapper {
			transition: height time(default) ease(inout);
			overflow: hidden;
			
			&--content {
				padding: 10px 0 30px;
			}
		}

		/** Active. */
		&.active {
			.accordion-header svg {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}
}