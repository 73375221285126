#page-privacy-code {
	
	/** Section content. */
	.section-content {
		padding: 80px 0 150px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.wrapper {
			.item {
				&:not(:last-child) {
					margin-bottom: 80px;
				}
				
				&-title {
					color: color(grey);
					font-size: 24px;
					font-family: font(title);
					font-weight: normal;
					margin-bottom: 20px;
				}
			}
		}
	}
}