#main-footer {
	background-color: #F3F3F3;
	position: relative;
	padding: 100px 0 40px;

	/** Mobile. */
	@media (max-width: 1023px) {
		padding: 65px 0 40px;
	}

	.container .flex-wrapper {
		&:nth-child(1) {
			align-items: flex-start;
			padding-bottom: 50px;
			border-bottom: 1px solid #D9D9D9;

			/** Mobile. */
			@media (max-width: 1023px) {
				padding: 0;
			}
		}

		&:nth-child(2) {
			margin-top: 35px;
			align-items: center;

			.right {
				margin-left: 170px;
				flex-shrink: 0;

				/** Mobile. */
				@media (max-width: 1023px) {
					margin: 30px 0 0;
				}
			}
		}

		&:nth-child(3) {
			align-items: center;
			margin-top: 30px;
		}
	}

	.flex-wrapper {
		display: flex;
		justify-content: space-between;

		/** Mobile. */
		@media (max-width: 1023px) {
			flex-wrap: wrap;
		}

		.column {
			width: 270px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
				margin-bottom: 50px;
			}

			&-title {
				display: flex;
				align-items: center;
				padding-bottom: 20px;
				border-bottom: 1px solid color(orange);

				svg path {
					fill: color(orange);
				}

				span {
					color: #231F20;
					font-size: 18px;
					font-family: font(title);
					font-weight: normal;
					display: inline-block;
					margin-left: 10px;
				}
			}

			&-description {
				margin-top: 30px;

				> * {
					font-size: 16px;
				}

				strong {
					color: color(grey);
				}
			}

			&-social {
				margin-top: 25px;

				ul {
					display: flex;
					align-items: center;

					li {
						position: relative;

						&:not(:last-child) {
							margin-right: 20px;
						}
						
						a {
							background-color: white;
							width: 50px;
							height: 50px;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							transition: background-color time(default) ease(inout);
							
							svg path {
								fill: color(orange);
								transition: fill time(default) ease(inout);
							}

							&:hover {
								background-color: color(orange);

								svg path {
									fill: white;
								}
							}
						}
					}
				}
			}

			&-navigation ul {
				li {
					display: block;

					&:not(:last-child) {
						margin-bottom: 8px;
					}
					
					a {
						color: #231F20;
						font-size: 16px;
						font-weight: normal;
						display: inline-block;
						transition: color time(default) ease(inout);

						&:hover {
							color: color(orange);
						}
					}
				}
			}
		}

		.left {
			flex-grow: 1;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
				flex-grow: unset;
			}

			.content {
				> * {
					font-size: 14px;
				}
			}
		}

		.right {
			
			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}

			ul {
				display: flex;
				align-items: center;

				/** Mobile. */
				@media (max-width: 1023px) {
					justify-content: center;
				}

				li {
					position: relative;

					&:not(:last-child) {
						margin-right: 17px;
					}
					
					a {
						display: inline-block;
					}
				}
			}
		}

		.copyright {
			color: color(grey);
			font-size: 14px;
			font-weight: normal;
			line-height: 1.2;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
				text-align: center;
				margin-bottom: 30px;
			}
		}

		.signature {

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}

			a {
				display: flex;
				align-items: center;

				/** Mobile. */
				@media (max-width: 1023px) {
					justify-content: center;
				}

				span {
					color: color(orange);
					font-size: 16px;
					font-weight: normal;
					display: inline-block;
					margin-right: 10px;
				}

				svg {
					position: relative;
					top: -1px;
					transform-origin: 0% 50%;
					transform: scale(1.2);

					path {
						fill: #939393;
						transition: fill time(default) ease(inout);
					}
				}

				&:hover svg path {
					fill: color(orange);
				}
			}
		}
	}
}