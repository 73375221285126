#page-regulatory-disclosures {

	/** Secction content. */
	.section-content {
		padding: 60px 0 120px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.flex-wrapper {
			display: flex;
			align-items: flex-start;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			.column {

				/** Mobile. */
				@media (max-width: 1023px) {
					width: 100%;
				}

				&:not(:last-child) {
					margin-right: 130px;

					/** Mobile. */
					@media (max-width: 1023px) {
						margin: 0 0 50px;
					}
				}
				
				&-title {
					color: color(grey);
					font-size: 18px;
					font-weight: bold;
					margin-bottom: 35px;
				}
			}
		}
	}
}