#page-contact-us {

	/** Section content. */
	.section-content {
		padding: 80px 0 115px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.description {
			padding-right: 110px;

			/** Mobile. */
			@media (max-width: 1023px) {
				padding-right: 0;
			}
		}

		.wrapper {
			margin-top: 80px;

			/** Mobile. */
			@media (max-width: 1023px) {
				margin-top: 50px;
			}

			&-title {
				color: color(grey);
				font-size: 36px;
				font-family: font(title);
				font-weight: normal;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 24px;
				}
			}

			&-description {
				margin-top: 30px;
				padding-right: 110px;

				/** Mobile. */
				@media (max-width: 1023px) {
					padding-right: 0;
				}
			}

			.accordions-1 {
				margin-top: 70px;
			}

			.accordions-2 {
				margin-top: 30px;
			}

			.persons {
				margin-bottom: 20px;

				.person {
					display: flex;
					align-items: center;

					/** Mobile. */
					@media (max-width: 1023px) {
						flex-direction: column;
						justify-content: center;
					}

					&:not(:last-child) {
						margin-bottom: 60px;
					}

					&-image {
						position: relative;
						width: 140px;
						height: 140px;
						border-radius: 50%;
						overflow: hidden;
						margin-right: 60px;
						flex-shrink: 0;

						/** Mobile. */
						@media (max-width: 1023px) {
							margin: 0 0 30px;
						}

						img {
							width: 100%;
							height: 100%;
							object-position: cover;
						}
					}

					&-content {
						flex-grow: 1;

						/** Mobile. */
						@media (max-width: 1023px) {
							text-align: center;
						}

						&--title {
							color: color(grey);
							font-size: 24px;
							font-family: font(title);
							font-weight: normal;
						}

						&--subtitle {
							color: color(grey);
							font-size: 18px;
							font-weight: bold;
							margin-top: 10px;
						}

						&--phone {
							color: color(grey);
							font-size: 18px;
							font-weight: normal;
							margin-top: 10px;
						}

						&--email {
							margin-top: 10px;

							a {
								color: #B45004;
								font-size: 18px;
								font-weight: normal;
								border-bottom: 1px solid color(orange);
								transition: color time(default) ease(inout);

								&:hover {
									color: color(grey);
								}
							}
						}
					}
				}
			}
		}

		.map {
			position: relative;
			width: 100%;
			height: 430px;
			margin-top: 65px;

			/** Mobile. */
			@media (max-width: 1023px) {
				height: 250px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}