#page-accessibility-statement {

	/** Section accordions. */
	.section-accordions {
		padding: 60px 0 105px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}
	}

	/** Section content. */
	.section-content {
		padding: 75px 0 115px;

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.description {
			margin-bottom: 90px;
		}

		.wrapper {
			.item {

				&:not(:last-child) {
					margin-bottom: 60px;
				}

				&-title {
					color: color(grey);
					font-size: 24px;
					font-family: font(title);
					font-weight: normal;
					margin-bottom: 15px;

					/** Mobile. */
					@media (max-width: 1023px) {
						font-size: 21px;
					}
				}
			}
		}
	}
}