#page-coming-soon {


  /** Section content. */
  .section-content {
    padding: 100px 0;

    .coming-soon h2 {
      color: color(grey);
      font-size: 36px;
      font-weight: bold;
    }
  }
}