.section-cta {
	position: relative;
	width: 100%;
	z-index: 2;

	.container {

		/** Mobile. */
		@media (max-width: 1300px) {
			padding: 0;
		}
	}

	.flex-wrapper {
		position: relative;
		display: flex;

		/** Mobile. */
		@media (max-width: 767px) {
			flex-wrap: wrap;
		}

		.left {
			position: relative;
			width: calc(50% + 30px);
			min-height: 320px;
			flex-shrink: 0;
			z-index: 2;

			/** Mobile. */
			@media (max-width: 767px) {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				min-height: auto;
				padding: 60px 0 90px;
			}

			&-image {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
				clip-path: polygon(0 0, calc(100% - 30px) 0, calc(100% - 30px) calc(50% - 30px), 100% 50%, calc(100% - 30px) calc(50% + 30px), calc(100% - 30px) 100%, 0 100%);

				/** Mobile. */
				@media (max-width: 767px) {
					clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), calc(50% + 30px) calc(100% - 30px), 50% 100%, calc(50% - 30px) calc(100% - 30px), 0 calc(100% - 30px));
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			&-title {
				color: white;
				font-size: 36px;
				font-family: font(title);
				font-weight: normal;
				line-height: 1.2;
				width: 400px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;

				/** Mobile. */
				@media (max-width: 1023px) {
					width: auto;
					max-width: 400px;
				}
				@media (max-width: 767px) {
					font-size: 21px;
					position: relative;
					top: auto;
					left: auto;
					transform: unset;
				}

				strong {
					font-weight: normal;
					border-bottom: 2px solid white;
					padding-bottom: 3px;
				}
			}
		}

		.right {
			background-color: #F4F4F5;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 50%;
			margin-left: -30px;
			flex-shrink: 0;
			padding: 40px 115px;
			z-index: 1;

			/** Mobile. */
			@media (max-width: 1023px) {
				padding: 60px 25px 60px 45px;
			}
			@media (max-width: 767px) {
				width: 100%;
				margin: -30px 0 0;
				padding: 60px 25px;
			}

			.content strong {
				color: color(grey);
			}

			.cta {
				margin-top: 55px;

				a {
					display: inline-flex;
					align-items: center;

					span {
						color: #231F20;
						font-family: font(title);
						font-size: 16px;
						font-weight: normal;
						margin-right: 15px;
					}

					svg {
						position: relative;
						bottom: -2px;
						transform: translateX(0);
						transition: transform time(default) ease(inout);

						path {
							fill: color(orange);
							transition: fill time(default) ease(inout);
						}
					}

					&:hover {
						span {

						}

						svg {
							transform: translateX(10px);

							path {
								fill: #231F20;
							}
						}
					}
				}
			}
		}
	}
}