#page-vision-values {

	/** Section content. */
	.section-content {
		padding: 90px 0 145px;

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.wrapper {
			&:not(:last-child) {
				margin-bottom: 60px;
			}

			&-title {
				color: color(orange);
				font-size: 36px;
				font-family: font(title);
				font-weight: normal;
				margin-bottom: 30px;
			}
		}
	}
}