#page-investment-services {

	/** Section accordions. */
	.section-accordions {
		padding: 70px 0 120px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.description {
			margin-bottom: 60px;
			padding-right: 70px;

			/** Mobile. */
			@media (max-width: 1023px) {
				padding-right: 0;
			}
		}
		
		.wrapper {
			&:not(:last-child) {
				margin-bottom: 70px;
			}

			&-title {
				color: color(grey);
				font-size: 36px;
				font-family: font(title);
				font-weight: normal;
				margin-bottom: 30px;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 24px;
				}
			}
		}
	}

	/** Section blocks. */
	.section-blocks {
		padding: 150px 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 100px 0 70px;
		}

		.message {
			margin-top: 50px;

			/** Mobile. */
			@media (max-width: 1023px) {
				margin-top: 30px;
			}

			p {
				color: white;
			}
		}
	}
}