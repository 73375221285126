#page-declaration-and-agreement {

	/** Section page hero. */
	.section-page-hero .wrapper .wrapper-title {
		width: 800px;

		/** Mobile. */
		@media (max-width: 1023px) {
			width: 100%;
		}
	}

	/** Section content. */
	.section-content {
		padding: 85px 0 100px;

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.description {
			margin-bottom: 65px;
		}

		.wrapper {
			.item {
				display: flex;
				align-items: flex-start;

				&:not(:last-child) {
					margin-bottom: 40px;
				}

				&-count {
					color: color(grey);
					font-size: 18px;
					font-weight: bold;
					width: 35px;
					text-align: left;
					position: relative;
					flex-shrink: 0;
					bottom: -5px;
				}

				&-description p strong {
					color: color(grey);
				}
			}
		}
	}
}