#page-borrowing-residential-mortgage {

	/** Section page hero. */
	.section-page-hero .wrapper .wrapper-title {
		width: 100%;
	}

	/** Section content. */
	.section-content {
		padding: 80px 0 150px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.testimonial {
			margin-bottom: 80px;

			&-description {
				margin-bottom: 35px;

				p {
					color: color(grey);
					font-size: 22px;
					font-weight: 300;
					font-style: italic;
					line-height: 1.5;
				}
			}

			&-author {
				display: flex;

				span {
					color: color(grey);
					font-size: 22px;
					font-weight: 300;
					font-style: italic;

					strong {
						font-weight: bold;
					}
				}
			}
		}

		.description {
			margin-bottom: 80px;
		}
	}
}