#page-search {

	/** Section content. */
	.section-content {
		padding: 75px 0 185px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.title {
			color: #231F20;
			font-size: 24px;
			font-family: font(title);
			font-weight: normal;
			margin-bottom: 50px;

			/** Mobile. */
			@media (max-width: 1023px) {
				font-size: 18px;
			}
		}
	}
}