#page-appraisal-policy {

	/** Section content. */
	.section-content {
		padding: 80px 0 90px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 80px;
		}

		.wrapper {
			&:not(:first-child) {
				margin-top: 75px;
			}

			&-title {
				color: color(grey);
				font-size: 28px;
				font-family: font(title);
				font-weight: normal;

				/** Mobile. */
				@media (max-width: 1023px) {
					font-size: 21px;
				}
			}

			&-description {
				margin-top: 40px;
				padding: 0 300px 0 115px;

				/** Mobile. */
				@media (max-width: 1023px) {
					margin-top: 35px;
					padding: 0;
				}

				p {
					padding-left: 20px;
				}
			}
		}

		.question {
			margin-top: 100px;

			&-title {
				color: color(grey);
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 4px;
			}
		}
	}
}