#page-terms-of-use {

	/** Section accordions. */
	.section-accordions {
		padding: 80px 0 90px;

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.title {
			color: color(grey);
			font-size: 18px;
			font-weight: bold;
		}

		.description {
			margin: 5px 0 70px;

			strong {
				font-weight: normal;
			}
		}

		.accordions .accordion .accordion-wrapper .accordion-wrapper--content {
			padding-right: 100px;

			/** Mobile. */
			@media (max-width: 1023px) {
				padding-right: 0;
			}

			.item {
				display: flex;
				align-items: flex-start;

				&:not(:last-child) {
					margin-bottom: 40px;
				}

				&-number {
					color: #231F20;
					font-size: 18px;
					font-weight: bold;
					width: 50px;
					margin: 5px 0 0;
					flex-shrink: 0;
				}
			}
		}
	}
}