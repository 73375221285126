#page-deposit-insurance-information {

	/** Section page hero. */
	.section-page-hero .wrapper .wrapper-title {
		width: 100%;
	}

	/** Section content. */
	.section-content {
		padding: 65px 0 100px;

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.main-description {
			display: flex;
			align-items: flex-start;
			margin-bottom: 55px;

			/** Mobile. */
			@media (max-width: 1023px) {
				flex-wrap: wrap;
			}

			img {
				order: 2;
				margin-left: 100px;
				flex-shrink: 0;

				/** Mobile. */
				@media (max-width: 1023px) {
					order: 1;
					margin: 0 0 40px;
				}
			}

			.text {
				order: 1;

				/** Mobile. */
				@media (max-width: 1023px) {
					order: 2;
				}
			}
		}

		.description {
			margin-bottom: 70px;
		}

		.wrapper {
			.item {

				&:not(:last-child) {
					margin-bottom: 50px;
				}

				&-title {
					color: color(grey);
					font-size: 18px;
					font-family: font(title);
					font-weight: normal;
					margin-bottom: 25px;
				}

				&-description p strong {
					color: color(grey);
				}
			}
		}
	}
}