.button {
	position: relative;
	display: inline-block;
	padding: 23px 27px 22px;
	min-width: 200px;
	text-align: center;
	border-radius: 50px;
	overflow: hidden;
	transition: box-shadow time(default) ease(inout);

	span {
		font-size: 18.66px;
		font-family: font(title);
		font-weight: normal;
		display: inline-block;
		position: relative;
		transition: color time(default) ease(inout);
		z-index: 2;
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		transition: opacity time(default) ease(inout);
	}

	&::before {
		opacity: 1;
	}

	&::after {
		opacity: 0;
	}

	/** Hover. */
	&:hover {
		box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.3);
	}

	/** Variations [Theme]. */
	&-orange {
		&::before {
			background-color: color(orange);
		}

		&::after {
			background-color: white;
		}

		span {
			color: white;
		}

		&:hover {
			&::before {
				opacity: 0;
			}

			&::after {
				opacity: 1;
			}

			span {
				color: color(orange);
			}
		}
	}

	&-orange-2 {
		&::before {
			background-color: color(orange2);
		}

		&::after {
			background-color: white;
		}

		span {
			color: white;
		}

		&:hover {
			&::before {
				opacity: 0;
			}

			&::after {
				opacity: 1;
			}

			span {
				color: color(orange2);
			}
		}
	}

	&-grey {
		&::before {
			background-image: linear-gradient(
				to right,
				color(grey),
				darken(color(grey), 5%)
			);
		}

		&::after {
			background-color: white;
		}

		span {
			color: white;
		}

		&:hover {
			&::before {
				opacity: 0;
			}

			&::after {
				opacity: 1;
			}

			span {
				color: color(grey);
			}
		}
	}
}
