#page-calculator {
  /** Section calculator. */
  .section-calculator {
    padding: 60px 0 80px;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 60px 0 0;
    }

    .container {
      /** Mobile. */
      @media (max-width: 1023px) {
        padding: 0;
      }
    }

    .title {
      color: color(grey);
      font-size: 28px;
      font-family: font(title);
      font-weight: normal;
      line-height: 1.2;
      margin-bottom: 60px;

      /** Mobile. */
      @media (max-width: 1023px) {
        font-size: 21px;
        margin-bottom: 40px;
        padding: 0 25px;
      }
    }

    .description {
      margin-bottom: 75px;

      /** Mobile. */
      @media (max-width: 1023px) {
        padding: 0 25px;
      }
    }

    .navigation {
      margin-bottom: 75px;

      ul {
        display: flex;

        /** Mobile. */
        @media (max-width: 1023px) {
          flex-wrap: wrap;
        }

        li {
          position: relative;
          width: calc(50% - 15px);

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 100%;
          }

          &:not(:last-child) {
            margin-right: 30px;

            /** Mobile. */
            @media (max-width: 1023px) {
              margin-right: 0;
            }
          }

          &::before {
            content: "";
            background-color: #f3f3f3;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            transition: background-color time(default) ease(inout);
          }

          &::after {
            content: "";
            position: absolute;
            bottom: -21px;
            left: calc(50% - 20px);
            opacity: 0;
            transform: translateY(-21px);
            transition: opacity time(default) ease(inout),
              transform time(default) ease(inout);
            width: 1px;
            height: 1px;
            border-top: 20px solid color(orange);
            border-right: 20px solid transparent;
            border-left: 20px solid transparent;

            /** Mobile. */
            @media (max-width: 1023px) {
              display: none;
            }
          }

          button {
            color: color(grey);
            font-family: font(title);
            font-size: 24px;
            font-weight: normal;
            position: relative;
            z-index: 3;
            width: 100%;
            height: 80px;
            transition: color time(default) ease(inout);

            /** Mobile. */
            @media (max-width: 1023px) {
              font-size: 18px;
            }
          }

          &.active,
          &:hover {
            &::before {
              background-color: color(orange);
            }

            button {
              color: white;
            }
          }

          &.active {
            &::after {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }

    .tabs {
      position: relative;
      transition: height time(default) ease(inout);

      /** Mobile. */
      @media (max-width: 1023px) {
        width: 100%;
      }

      .tab-content {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: opacity time(default) ease(inout),
          visibility time(default) ease(inout);

        &.active {
          opacity: 1;
          visibility: visible;
          z-index: 2;
          transition-delay: 0.3s;
        }
      }
    }

    &.calculator-rrif .calculator {
      min-height: 500px;
    }

    .calculator {
      background-color: #f3f3f3;
      box-shadow: 0px 40px 80px 0px rgba(35, 31, 32, 0.2);
      display: flex;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-wrap: wrap;
        box-shadow: unset;
      }

      .left {
        position: relative;
        padding: 100px 60px;
        padding-right: 130px;
        flex-grow: 1;

        /** Mobile. */
        @media (max-width: 1023px) {
          padding: 40px 25px;
          flex-grow: unset;
          width: 100%;
        }

        .error-message,
        .alert-message {
          font-size: 14px;
          color: #dd0000;
          background: fade-out(red, 0.9);
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 12px 15px 9px;
          pointer-events: none;
          transition: opacity 250ms;

          &:not(.show) {
            opacity: 0;
          }
        }

        .error-message {
          z-index: 2;
        }

        .alert-message {
          background-color: #fff3cd;
          color: black;
        }

        .form {
          &-row {
            position: relative;
            display: flex;
            align-items: center;

            &:nth-child(1) {
              z-index: 10;
            }

            &:nth-child(2) {
              z-index: 9;
            }

            &:nth-child(3) {
              z-index: 8;
            }

            &:nth-child(4) {
              z-index: 7;
            }

            &:nth-child(5) {
              z-index: 6;
            }

            &:nth-child(6) {
              z-index: 5;
            }

            &:nth-child(7) {
              z-index: 4;
            }

            &:nth-child(8) {
              z-index: 3;
            }

            /** Mobile. */
            @media (max-width: 1023px) {
              flex-wrap: wrap;
            }

            &:not(:last-child) {
              margin-bottom: 40px;
            }

            &--left {
              position: relative;
              width: 340px;
              flex-shrink: 0;
              z-index: 2;

              /** Mobile. */
              @media (max-width: 1023px) {
                text-align: center;
                width: 100%;
                margin-bottom: 30px;
              }
            }

            &--right {
              position: relative;
              flex-grow: 1;
              z-index: 1;

              .flex {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .field-group {
                  width: calc(50% - 15px;);
                }
              }
            }

            .tooltip {
              position: relative;
              display: inline-block;
              width: auto;

              &-label {
                color: #231f20;
                font-size: 18px;
                font-family: font(title);
                font-weight: normal;
              }

              &-icon {
                position: absolute;
                top: -5px;
                right: -23px;

                svg path {
                  fill: color(orange);
                }
              }

              &-description {
                background-color: white;
                box-shadow: 0px 20px 40px 0px rgba(36, 35, 36, 0.3);
                position: absolute;
                top: 50%;
                left: calc(100% + 45px);
                transform: translate(10px, -50%);
                width: 250px;
                padding: 10px 20px;
                border-radius: 10px;
                pointer-events: none;
                opacity: 0;
                transition: opacity time(default) ease(inout),
                  transform time(default) ease(inout);
                z-index: 5;

                /** Mobile. */
                @media (max-width: 1023px) {
                  top: calc(100% + 30px);
                  left: 50%;
                  width: 90vw;
                  transform: translate(-50%, 10px);
                }

                &::before {
                  content: "";
                  position: absolute;
                  top: calc(50% - 13px);
                  left: -9px;
                  width: 1px;
                  height: 1px;
                  border-top: 8px solid transparent;
                  border-bottom: 8px solid transparent;
                  border-right: 8px solid white;

                  /** Mobile. */
                  @media (max-width: 1023px) {
                    top: -9px;
                    left: calc(50% - 8px);
                    border: none;
                    border-right: 8px solid transparent;
                    border-left: 8px solid transparent;
                    border-bottom: 8px solid white;
                  }
                }

                > * {
                  font-size: 16px;
                }
              }

              &:hover {
                .tooltip-description {
                  opacity: 1;
                  transform: translate(0, -50%);

                  /** Mobile. */
                  @media (max-width: 1023px) {
                    transform: translate(-50%, 0);
                  }
                }
              }
            }

            .field-group {
              position: relative;

              /** Variations. */
              &[data-type="text"],
              &[data-type="read"] {
                .field {
                  background-color: transparent;
                  color: color(grey);
                  font-size: 24px;
                  font-weight: normal;
                  padding-bottom: 5px;
                  text-align: right;
                  border: none;
                  width: 100%;
                  border-bottom: 1px solid color(grey);

                  &::placeholder {
                    color: color(grey);
                  }

                  &[type="number"] {
                    appearance: none;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                }
              }

              &[data-type="read"] {
                .field {
                  border: none;
                }
              }

              &[data-type="options"] {
                display: flex;
                align-items: center;

                .option {
                  width: 50%;

                  &:not(:last-child) {
                    margin-right: 30px;
                  }

                  .field {
                    display: none;
                  }

                  label {
                    color: color(grey);
                    font-size: 18px;
                    line-height: 42px;
                    text-align: center;
                    border-radius: 20px;
                    display: inline-block;
                    width: 100%;
                    height: 40px;
                    border: 1px solid color(grey);
                    cursor: pointer;
                    transition: color 250ms, background 250ms;
                  }

                  &:hover label,
                  .field:checked ~ label {
                    color: white;
                    background: color(grey);
                  }
                }
              }

              &[data-type="radio"] {
                margin-bottom: 35px;

                .options {
                  .option {
                    position: relative;

                    &::before,
                    &::after {
                      content: "";
                      position: absolute;
                      border-radius: 50%;
                    }

                    &::before {
                      background-color: #f3f3f3;
                      width: 6px;
                      height: 6px;
                      top: 6px;
                      left: 8px;
                      z-index: 2;
                    }

                    &::after {
                      width: 18px;
                      height: 18px;
                      top: -2px;
                      left: 0;
                      z-index: 1;
                      border: 2px solid #231f20;
                      transition: background-color time(default) ease(inout),
                        border-color time(default) ease(inout);
                    }

                    &:not(:last-child) {
                      margin-bottom: 45px;
                    }

                    .tooltip {
                      padding: 0 0 0 40px;

                      /** Mobile. */
                      @media (max-width: 1023px) {
                        width: 100%;
                      }

                      &-label {
                        font-size: 20px;
                        font-family: font(main);
                        font-weight: normal;
                      }
                    }

                    &.active {
                      &::after {
                        border-color: color(orange);
                        background-color: color(orange);
                      }
                    }

                    &:hover {
                      &::after {
                        border-color: color(orange);
                      }
                    }
                  }
                }
              }

              &[data-type="select"] {
                &.active {
                  z-index: 5;

                  .list {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: initial;
                  }
                }

                .box {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  border-bottom: 1px solid #999799;
                  padding-bottom: 10px;

                  span {
                    color: color(grey);
                    font-size: 23px;
                    font-weight: normal;
                    margin-right: 25px;
                    transition: color time(default) ease(inout);
                  }

                  svg path {
                    stroke: color(grey);
                  }
                }

                .list {
                  background-color: color(grey);
                  position: absolute;
                  top: calc(100% - 1px);
                  right: 0;
                  left: 0;
                  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.1);
                  opacity: 0;
                  visibility: hidden;
                  transition: opacity time(default) ease(inout),
                    visibility time(default) ease(inout);
                  max-height: 180px;
                  overflow: auto;
                  pointer-events: none;

                  ul li {
                    display: block;

                    button {
                      position: relative;
                      width: 100%;
                      color: white;
                      font-size: 18px;
                      font-weight: normal;
                      padding: 10px 0;
                      transition: color time(default) ease(inout),
                        background-color time(default) ease(inout);

                      &:hover {
                        background-color: white;
                        color: color(grey);
                      }
                    }
                  }
                }

                &.not-empty .box span {
                  color: #231f20;
                }
              }
            }
          }

          &-title {
            color: color(orange);
            font-size: 24px;
            font-family: font(title);
            font-weight: normal;
            margin: 0 0 30px;
          }

          .separator {
            color: color(grey);
            font-size: 24px;
            font-family: font(title);
            font-weight: normal;
            margin: 75px 0 30px;
          }

          .prepayment {
            position: relative;
            margin-top: 70px;

            &-button {
              color: color(orange);
              font-size: 18px;
              font-weight: bold;
              border-bottom: 1px solid color(orange);
              transition: color time(default) ease(inout),
                border-color time(default) ease(inout);

              &:hover {
                color: color(grey);
                border-color: color(grey);
              }
            }

            &-fields {
              overflow: hidden;
              transition: height time(default) ease(inout);

              .form-row {
                &:nth-child(1) {
                  padding-top: 30px;
                }
              }
            }
          }
        }
      }

      .right {
        background-color: color(grey);
        position: relative;
        width: 370px;
        flex-shrink: 0;
        padding: 130px 30px 0;

        &-button {
          position: absolute;
          top: 0;
          left: 0;
        }

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
          padding: 40px 25px 60px;
        }

        &.small {
          padding-top: 30px;

          /** Mobile. */
          @media (max-width: 1023px) {
            padding-top: 40px;
          }
        }

        &-title,
        &-money {
          color: white;
          font-family: font(title);
          font-weight: normal;
          display: block;
          text-align: center;
        }

        &-title {
          font-size: 24px;
          line-height: 1.2;
        }

        &-amortization {
          color: white;
          font-size: 21px;
          font-weight: normal;
          text-align: center;
          display: block;
          margin: 20px 0 0;
        }

        &-money {
          font-size: 41px;
          margin-top: 20px;

          span {
            color: white;
            font-family: font(main);
            font-size: 16px;
            font-weight: normal;
            display: block;
            margin-top: 5px;
          }
        }

        &-list {
          padding: 0 5px;
          margin-top: 55px;

          ul li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.prepayment {
              opacity: 0;
              visibility: hidden;
              transition: opacity time(default) ease(inout),
                visibility time(default) ease(inout);

              &.active {
                opacity: 1;
                visibility: visible;
              }
            }

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            span {
              color: white;
              font-size: 16px;
              font-weight: normal;

              &:nth-child(2) {
                font-weight: bold;
              }
            }
          }
        }

        &-description {
          position: absolute;
          right: 55px;
          bottom: 45px;
          left: 55px;

          /** Mobile. */
          @media (max-width: 1023px) {
            position: relative;
            right: unset;
            bottom: unset;
            left: unset;
            margin-top: 35px;
            text-align: center;
          }

          > * {
            color: white;
            font-size: 16px;
            line-height: 1.2;
          }
        }
      }
    }

    .wrapper {
      margin-top: 100px;

      /** Mobile. */
      @media (max-width: 1023px) {
        margin: 60px 0;
      }

      &-title {
        color: color(grey);
        font-size: 24px;
        font-family: font(title);
        font-weight: normal;
        line-height: 1.2;
        margin-bottom: 20px;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 21px;
          padding: 0 25px;
        }
      }

      &-description {

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
          padding: 0 25px;
        }

        a {
          color: #B45004;
          font-size: 18px;
          font-weight: normal;
          border-bottom: 1px solid color(orange);
          transition: color time(default) ease(inout);

          &:hover {
            color: color(grey) !important;
          }
        }
      }    
    }
  }
}
