#page-saving-deposits {

	/** Section content. */
	.section-content {
		padding: 80px 0 0;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding: 60px 0 0;
		}

		.container {
			width: 970px;

			/** Mobile. */
			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		.terms {
			&-title {
				color: color(orange);
				font-size: 24px;
				font-family: font(title);
				font-weight: normal;
			}

			&-description {
				margin-top: 15px;
			}

			&-items {
				display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 110px;

        /** Mobile. */
        @media (max-width: 1023px) {
        	flex-wrap: wrap;
          justify-content: center;
        }

        .item {
          width: 240px;

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 100%;
            margin: 0 0 50px;
          }

        	&-icon {
        		height: 95px;
        		text-align: center;
        	}

      		&-title {
	        	color: #231F20;
	        	font-size: 18px;
	        	font-family: font(title);
	      		font-weight: normal;
	      		margin-bottom: 35px;
	      		text-align: center;
      		}
        }
			}
		}

		.accordions {
			margin-top: 95px;
		}
	}

  .separator {
    margin-bottom: 150px;

    /** Mobile. */
    @media (max-width: 1023px) {
      margin-bottom: 80px;
    }
  }

	/** Section blocks. */
	.section-blocks {
		margin-top: -150px;
		padding-top: 250px;

		/** Mobile. */
		@media (max-width: 1023px) {
			padding-top: 100px;
			margin: 0;
		}
	}
}